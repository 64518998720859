'use strict';

class NavbarController {
  constructor(Cart, Finding, usSpinnerService, $state, Auth, Util, $rootScope, $location) {
    this.$rootScope = $rootScope;
    this.Finding = Finding;
    this.Cart = Cart;
    this.usSpinnerService = usSpinnerService;
    this.isLoggedIn = Auth.isLoggedIn;
    this.Auth = Auth;
    this.getDefectRecommendation = Util.getDefectRecommendation;
    this.counter = {};
    this.sharedLevel = localStorage.getItem('sharedLevel');
    $rootScope.findingsCategory = {
      'cat0': false,
      'cat1': false,
      'cat2': false,
      'cat3': false,
      'cat4': false,
      'cat5': false,
    };
    this.isMainPage = false;
    this.isMapSelected = false;
    Auth.isLoggedInAsync((loggedIn)=> {
      if (loggedIn) {
        if(!this.sharedLevel) {
          this.init();
        }
      } else {
        $state.go('login');
      }
    });
    //broadcast to ListFindings.controller to refresh findings
    $rootScope.$on('updateCounter', (event, args)=> {
      this.init();
    });
    $rootScope.$on('updateCart', (event, args)=> {
      this.cartFindingCount = args;
    });

    // $rootScope.$on('isMapSelected', (event, isMapSelected) => {
    //   this.isMapSelected = isMapSelected;
    // });

    // $rootScope.$on('resetFilterWindFarm', (event) => {
    //   this.resetFilterWindFarm();
    // });

    // $rootScope.$watch(function() {
    //   return $location.path();
    // }, (newPath, oldPath) => {
    //   if(newPath == '/') {
    //     this.isMainPage = true;
    //   } else {
    //     this.isMainPage = false;
    //     this.resetFilterWindFarm();
    //   }
    // });
  }

  init() {
    this.loading = true;
    this.counter = null;
    this.Finding.get({id: 'counts', controller: 'get-all', page: 'count'}).$promise
      .then(response=> {
        if (response.data) {
          this.counter = response.data;
        }
        this.loading = false;
        this.stopSpin('spinner-1');
      })
      .catch(err=> {
        console.log(err);
        this.stopSpin('spinner-1');
        this.loading = false;
        this.counter = null;
      })

    this.Cart.getByOrganization({ controller: this.Auth.getCurrentUser().organization._id }).$promise
      .then(response=>{
        this.cartFindingCount = response.data && response.data.findings.length;
      })
      .catch(err=>{
        this.cartFindingCount = null;
      });
  }

  updateFindingsCategorySelection(category) {
      if(category == 'cat0') {
        this.$rootScope.findingsCategory.cat0 = !this.$rootScope.findingsCategory.cat0;
      } else if(category == 'cat1') {
        this.$rootScope.findingsCategory.cat1 = !this.$rootScope.findingsCategory.cat1;
      } else if(category == 'cat2') {
        this.$rootScope.findingsCategory.cat2 = !this.$rootScope.findingsCategory.cat2;
      } else if(category == 'cat3') {
        this.$rootScope.findingsCategory.cat3 = !this.$rootScope.findingsCategory.cat3;
      } else if(category == 'cat4') {
        this.$rootScope.findingsCategory.cat4 = !this.$rootScope.findingsCategory.cat4;
      } else if(category == 'cat5') {
        this.$rootScope.findingsCategory.cat5 = !this.$rootScope.findingsCategory.cat5;
      }
      // $rootScope.$broadcast('filterWindFarm', $rootScope.findingsCategory);
  }

  resetFilterWindFarm() {
    this.findingsCategory = {
      'cat0': false,
      'cat1': false,
      'cat2': false,
      'cat3': false,
      'cat4': false,
      'cat5': false,
    };
  }

  startSpin(spinner) {
    this.usSpinnerService.spin(spinner);
  }

  stopSpin(spinner) {
    this.usSpinnerService.stop(spinner);
  }
}

angular.module('windmanagerApp')
  .controller('NavbarController', NavbarController);
